.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height; or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2a292b;
}

.tabs {
    margin: 20px 0px;
    width: 60%;
}

.cardContainer {
    display: flex;
    flex-direction: row;
}

.cardSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 24px;
    margin-top: 10px;
    margin-bottom: 10%;

    background: #FFFFFF;
}

.cardSection_inner {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 24px;
}