.cardContainer {
    display: flex;
    flex-direction: row;
    /* height: 414px; */

}

.sliderContainer {
    width: 69em;
    height: 120%;
    margin: "0 auto";

}

.nextButton {
    width: 100px;
    height: 100px;
    font-size: 50px;
    margin-top: 150%;
    color: rgba(128, 127, 127, 0.7);
    transition: .25s;
    cursor: pointer;
}

.nextButton:hover {
    font-size: 70px;
    color: #807F7F;
}

@media screen and (max-width: 1191px) {

    .sliderContainer {
        width: 37rem;
    }
}

@media screen and (max-width: 800px) {
    .sliderContainer {
        width: 38rem;
    }
}