.container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 1rem;
  flex-direction: column;
}

.title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2a292b;
}

.categoryTitle {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 37px;
  margin-bottom: 34px;
  color: #2a292b;
}

.banner_image_div {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.banner_image {
  width: 80%;
  border-radius: 10px;
}

.subtitle {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #2a292b;
  margin-top: 17px;
}

.html {
  margin-top: 43px;
  margin-bottom: 93px;
  width: 50%;
}

.divider {
  margin-top: 3%;
  border-top: 1px solid rgba(128, 127, 127, 0.2);
  width: 80%;
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  .html {
    width: 75%;
    overflow: auto;
  }

}

@media screen and (max-width: 700px) {
  .html {
    width: 100%;
    overflow: auto;
  }
}