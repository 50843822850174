body {
  margin: 0;
  height: 100vh;
  font-weight: normal;
  font-family: "Manrope", "Source Sans Pro", "GEssArabic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "GEssArabic";
  font-weight: 400;
  src: url("./fonts/GE_SS_Two_Light.otf");
}

@font-face {
  font-family: "GEssArabic";
  font-weight: 500;
  src: url("./fonts/GE_SS_Two_Medium.otf");
}

@font-face {
  font-family: "GEssArabic";
  font-weight: 600;
  src: url("./fonts/GE_SS_Two_Bold.otf");
}

iframe #Embed {
  display: none !important;
}

iframe {
  border: none;
}

html {
  height: 100vh;
}

input,
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.title {
  font-size: 50px;
  color: #2a292b;
  font-weight: bold;
  max-width: 400px;
  line-height: 1.04;
}

.mobile-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2a292b;
  font-weight: bold;
  max-width: 300px;
  line-height: 1.04;
}

.containerA {
  /* height: calc(100vh - 4em); */
  padding: 6em 0px;
  position: relative;
}

.containerB {
  height: calc(100vh - 4em);
  padding: 6em 0px;
  position: relative;
}

.max_container {
  max-height: calc(100vh - 12em);
  overflow: auto;
  padding: 6em 0px;
  position: relative;
}

@media screen and (max-height: 500px) {
  .container {
    height: 100%;
    overflow: auto;
    padding: 6em 0px;
    position: relative;
  }
}

.mobile-container {
  display: flex;
  padding: 1rem;
  min-height: 36rem;
  padding-top: 0;
  /* max-height: calc(100vh - 23rem); */
  overflow: auto;
  /* margin-top: 8rem; */
  padding-bottom: 2rem;
  flex-direction: column;
  /* gap: 20px; */
}

.home-mobile-container {
  display: flex;
  /* padding: 1rem; */
  min-height: 36rem;
  padding-top: 0;
  /* max-height: calc(100vh - 23rem); */
  overflow: auto;
  /* margin-top: 8rem; */
  padding-bottom: 2rem;
  flex-direction: column;
  /* gap: 20px; */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #414142;
  /* Firefox */
}

#search::placeholder {
  color: black;
  font-weight: 600;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #414142;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #414142;
}

#link:hover #linktext {
  border-bottom: 1px solid #fff;
  margin: auto;
  margin-top: 0.5rem;
  width: 60%;
}

#linktext {
  border-bottom: 1px solid transparent;
  margin: auto;
  margin-top: 0.5rem;
  width: 60%;
}

.Line-2 {
  width: 100px;
  height: 3px;
  margin: 5% 0% 5% 0%;
  background-color: #c80600;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c80600 #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.37); */
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c80600;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  /* background: #fff; */
  background: #c80600;
}

.faq-slider {
  scrollbar-width: none !important;
}

#tabsInnerContainer {
  scrollbar-width: none !important;
}

#htmlContainer {
  scrollbar-width: none !important;
}

#tabsInnerContainer::-webkit-scrollbar-thumb {
  display: none;
}

#htmlContainer::-webkit-scrollbar-thumb {
  display: none;
}

.faq-slider::-webkit-scrollbar-thumb {
  display: none;
}

.branches_visitus::-webkit-scrollbar-thumb {
  background: #c80600 !important;
}

.branches_visitus::-webkit-scrollbar-thumb:hover {
  background: #c80600 !important;
}

.template_visitus::-webkit-scrollbar-thumb {
  background: #c80600 !important;
}

.template_visitus::-webkit-scrollbar-thumb:hover {
  background: #c80600 !important;
}

#contactSlides>*:nth-child(1) {
  animation-delay: 0s;
}

#contactSlides>* {
  animation: 12s autoplay2 infinite ease-in-out;
  position: absolute;
  top: 100%;
  left: 0;
}

@keyframes autoplay2 {
  0% {
    top: 100%;
  }

  4% {
    top: 0%;
  }

  33.33% {
    top: 0%;
  }

  37.33% {
    top: -100%;
  }

  100% {
    top: -100%;
  }
}

#slideset3 {
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
}

#slideset3>* {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#slideset3>*:first-child {
  animation-name: autoplay3;
  animation-duration: 12s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 4s;
}

@keyframes autoplay3 {
  0% {
    margin-top: 2em;
  }

  20% {
    margin-top: 2em;
  }

  40% {
    margin-top: 2em;
  }

  60% {
    margin-top: calc(100px - 100vh);
  }

  80% {
    margin-top: calc(100px - 100vh);
  }

  100% {
    margin-top: calc(100px - 100vh);
  }
}

#contactpause {
  display: none;
}

#contactpause:checked~#slideset3>* {
  animation-play-state: paused;
}

input[type="radio"] {
  position: absolute;
  display: none;
  opacity: 0;
  z-index: -1;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #414142;
  cursor: pointer;
}

.tab-label::after {
  content: "\002B";
  width: 2em;
  height: 2em;
  color: #c80600;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #e2101a;
  background: #eaeaea;
  transition: all 0.35s;
}

.tab {
  width: 100%;
  font-family: "Source Sans Pro";
  color: white;
  overflow: hidden;
}

input:checked~.tab-content {
  max-height: 15vh;
  overflow: auto;
  padding: 0 1em;
  padding-left: 0;
}

input[type="radio"]:checked+.tab-label::after {
  content: url("internalImages/minus.svg");
}

.dot {
  cursor: pointer;
  height: 17px;
  width: 17px;
  margin: 0 2px;
  background-color: #c80600;
  border-radius: 50%;
  display: inline-block;
  transition: width 0.6s, height 0.6s;
}

/* .active,
.dot:hover {
    height: 22px;
    width: 22px;
} */

.dot-mobile {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #e50000;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot-home {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #720404;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot-mobile:hover {
  background-color: #2a292b;
}

.slideshow-container {
  /* max-width: 1000px; */
  position: relative;
  /* margin: auto */
}

.slideshow-inner {
  position: relative;
}

.mySlides {
  display: none;
  height: 100%;
  /* height: 400px; */
  /* border: solid 1px black; */
}

.myContactSlides {
  display: none;
  height: 100%;
  /* height: 400px; */
  /* border: solid 1px black; */
}

.myDiscoverSlides {
  display: none;
  height: 100%;
  /* height: 400px; */
  /* border: solid 1px black; */
}

.smartphone {
  position: relative;
  width: 250px;
  height: 550px;
  margin: auto;
  border: 5px solid black;
  /* background-image: linear-gradient(to right, #7c7c7c 3124%, #767676 3125%, #565656 3131%, #3b3b3b 3137%, #252525 3144%, #141414 3153%, #080808 3163%, #010101 3178%, #000 3218%); */
  border-image: linear-gradient(153deg,
      #cacaca 196%,
      #c1c1c1 205%,
      #a9a9a9 219%,
      #838383 237%,
      #5b5b5b 254%,
      #f2f2f2 268%,
      #969696 293%) 9;
  border-radius: 2px;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 250px;
  height: 550px;
  background: white;
}

.marvel-device.iphone-x:before {
  border-radius: 30px;
  top: 3px !important;
  bottom: 3px !important;
  right: 3px !important;
  left: 3px !important;
  width: unset;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-dots {
  display: block;
  position: absolute;
  bottom: 6em;
  left: 6em;
  width: fit-content;
  text-align: left;
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .slick-dots {
    left: 1em;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  .slick-dots {
    left: 2em;
  }
}

.slick-dots-mobile {
  display: inline-flex !important;
  position: absolute;
  bottom: 7em;
  left: -2em;
  text-align: left;
}

.slick-dots-mobile .slick-active .dot-mobile {
  background-color: #2a292b;
}

.slick-dots-home {
  padding: 0px;
  display: inline-flex !important;
  position: absolute;
  /* bottom: -2%; */
  left: 0;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  justify-content: center;
}

.slick-dots-home .slick-active .dot-home {
  background-color: #fff;
}

.slick-dots .slick-active .dot {
  height: 20px;
  width: 20px;
}

.slick-slider {
  position: unset !important;
}

.slick-dots li {
  margin: 0 2px;
}

ul.slick-dots-mobile,
ul.slick-dots-home {
  list-style: none;
}

.slick-dots-mobile li,
.slick-dots-home li {
  margin: 0 2px;
}

.tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #414142;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover+.tooltiptext {
  visibility: visible;
}

@keyframes up {
  from {
    margin-top: 25px;
  }

  to {
    margin-top: 5px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
}

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
  color: #006080;
}

.tooltip1 .tooltiptext1 {
  position: absolute;
  width: 90px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 6px;
  line-height: 1.5;
  z-index: 1;
  opacity: 1;
}

.tooltip-bottom1 {
  top: 135%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-bottom1::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-20%, 80%);
  /* Style the bubble. */
  background-color: #807f7f;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  /* Center the tip horizontally. */
  transform: scale(1, -1);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #807f7f;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.video-overlay {
  z-index: 4;
  position: absolute;
  background-color: rgba(5, 5, 5, 0.45);
  /* width: 100%; */
  top: -8em;
  left: 0em;
  right: 0;
  overflow: hidden;
  bottom: 0;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

.close-btn {
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  justify-content: flex-end;
}

/* home css */

.home_grid_container {
  display: flex;
  /* margin: 0px 80px; */
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: 330px; */
  gap: 1em;
}

.home_grid {
  margin-right: 1em;
}

/* @media screen and (max-height : 500px){
  #home{
    overflow: auto;
    margin-top: 0px;
  }

  .left-container > :first-child{
    margin-top: 4em;
  }
  .left-container{
    margin-top: 7em;
  }

  #rightContainer{
    margin-top: 14em;
    overflow: hidden !important;
    height: unset;
    max-height: unset !important;
  }
} */

@media screen and (min-width: 701px) and (max-width: 900px) {
  .hand-image {
    display: none !important;
  }

  .middle-container {
    display: none !important;
  }

  #home .left-container1 div {
    left: 2em !important;
    top: -12em !important;
  }

  .left-container {
    margin-left: 1em !important;
  }

  .merchant {
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 5rem;
    left: 3%;
    width: 36px !important;
    box-shadow: inset 1px 0px 1px #00000029,
      0px 3px 6px #00000029;
    background: #C8060062 0% 0% no-repeat padding-box;
  }

  .merchant #text div {
    display: none;
  }
}

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .home_grid_container {
    grid-template-columns: 190px;
  }

  .home_grid {
    margin-right: 1em;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  .left-container {
    margin-left: 2em !important;
  }

  .merchant {
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 5rem;
    left: 2%;
    box-shadow: inset 1px 0px 1px #00000029,
      0px 3px 6px #00000029;
    background: #C8060062 0% 0% no-repeat padding-box;
  }
}

@media screen and (min-width: 1051px) {
  .merchant {
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 5rem;
    left: 3%;
    box-shadow: inset 1px 0px 1px #00000029,
      0px 3px 6px #00000029;
    background: #C8060062 0% 0% no-repeat padding-box;
  }
}

/* header css */

.header_right_container {
  width: 65%;
}

.social_footer_icons {
  margin: 0px 15px;
  cursor: pointer;
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .header_right_container {
    width: 75%;
  }

  .social_footer_icons {
    margin: 0px 5px;
    cursor: pointer;
  }
}

/* discover css */

/* discover fold 1 */

.discover_grid_container {
  padding-right: 3em;
}

@media screen and (min-width: 701px) and (max-width: 800px) {
  #d1wrapper {
    width: 12em !important;
    border-radius: 20px !important;
  }

  #d1wrapper>div {
    padding: 5px 10px !important;
  }

  .discover_grid_container {
    padding-right: 1em;
  }
}

@media screen and (min-width: 801px) and (max-width: 900px) {
  #d1wrapper {
    width: 15em !important;
    border-radius: 20px !important;
  }

  #d1wrapper>div {
    padding: 5px 15px !important;
  }

  .discover_grid_container {
    padding-right: 1.5em;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  #d1wrapper {
    width: 18em !important;
  }

  #d1wrapper>div {
    padding: 5px 15px !important;
  }

  .discover_grid_container {
    padding-right: 2em;
  }
}

/* discover fold2 */

@media screen and (min-width: 701px) and (max-width: 900px) {
  #d2wrapper {
    width: 220px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  #d2wrapper {
    width: 240px !important;
  }
}

/* Assistance */

@media screen and (min-width: 701px) and (max-width: 900px) {
  #assistancewrapper {
    width: 170px !important;
  }

  #assistanceGridConainer {
    margin-top: 2em;
    margin-left: -5em;
    grid-template-columns: auto auto auto !important;
    gap: 5px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  #assistancewrapper {
    width: 200px !important;
  }
}

/* visit us */

@media screen and (min-width: 701px) and (max-width: 900px) {
  #branchesWrapper {
    width: 190px !important;
  }

  #branchesGridContainer {
    grid-template-columns: auto auto !important;
  }

  .detailedStore {
    width: 520px !important;
    height: 310px !important;
  }

  .detailedStoremap {
    width: 500px !important;
    height: 310px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  #branchesWrapper {
    width: 220px !important;
  }

  #branchesGridContainer {
    grid-template-columns: auto auto !important;
  }

  .detailedStore {
    width: 640px !important;
    height: 340px !important;
  }

  .detailedStoremap {
    width: 620px !important;
    height: 340px !important;
  }
}

.contactus_right_container {
  padding: 0 8em;
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .contactus_right_container {
    padding: 0 4em;
  }
}

/* store */

.slick-slider {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.slick-slider .slick-list {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}

#homeslider .slick-list {
  /* border-radius: 50%; */
  /* background-clip: border-box; */
  padding: 0px;
  width: 100%;
}

#homeslider .slick-slide img {
  /* max-height: 398px;
  max-width: 398px;
  border-radius: 50%; */
  height: 300px;
  cursor: pointer;
  width: auto;
}

#homeslider .slick-slide video {
  max-height: 300px;
  width: auto;
  height: 300px;
  /* margin-top: 33%; */
  text-align: center;
  padding: 0% 34%;
  background-color: #C80600;
}

#homeslider .slick-slide iframe {
  max-height: 200px;
  max-width: 300px;
  height: auto;
  margin-top: 33%;
  text-align: center;
  width: auto;
}

/* mobile home slider */

#home-mobile-slider .slick-list {
  /* border-radius: 50%; */
  background-clip: border-box;
}

#home-mobile-slider .slick-slide img {
  max-height: 298px;
  max-width: 99.7%;
  margin: 0px 22px;
  height: auto;
  cursor: pointer;
}

#home-mobile-slider .slick-slide video {
  /* max-height: 150px;
  max-width: 225px; */
  height: auto;
  margin-top: 30%;
  text-align: center;
  width: auto;
}

#home-mobile-slider .slick-slide iframe {
  max-height: 150px;
  width: 350px;
  height: auto;
  margin-top: 30%;
  text-align: center;
  width: auto;
}

.tcpp {
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  background-color: #eaeaea;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
}

ul li div.p-small-1,
ul li div.n-small-1 {
  transform: scale(0.8);
}

.hide {
  display: none;
}

.sidemenulabel:hover {
  text-overflow: clip !important;
  white-space: normal !important;
  word-break: break-all !important;
  width: fit-content !important;
  padding-right: 20px;
}

.wallet_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  gap: 15px;
  justify-content: start;
  margin-top: 1rem;
  transform: scale(1);
}

.wallet {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(42, 41, 43);
  margin-left: 5px;
  display: flex;
}

.faq_grid {
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, 95px);
  gap: 10px;
  margin-top: 1rem;
  font-size: 14px;
}

@media screen and (max-width: 450px) {

  .wallet_grid,
  .wallet,
  .faq_grid {
    justify-content: center;
  }
}

.stop-scrolling {
  height: 100%;
}

@media screen and (max-width: 700px) {
  .tab-label {
    padding: 0.5em;
  }

  input:checked~.tab-content {
    padding: 0.25em 0.5em;
  }

  .tab-content {
    background-color: #fff;
  }

  .stop-scrolling {
    overflow: hidden;
  }
}

#branches {
  transition: height 2s;
  transition-timing-function: ease-out;
}

#customlauncher {
  position: fixed;
  top: 80%;
  right: 20px;
  cursor: pointer;
  z-index: 9;
}

#chatWindow {
  position: fixed;
  bottom: 2%;
  left: 70%;
  /* opacity: 0;
  pointer-events: none; */
  border: 0px;
  border-radius: 0.5rem;
  transition: width 2s;
}

#chatWindowFrame {
  border: 0px;
  border-radius: 0.5rem;
}

@media screen and (max-width: 1200px) {
  #chatWindow {
    left: 0%;
    bottom: 0%;
    z-index: 9;
    top: 0%
  }

  #chatWindowFrame {
    border: none;
  }
}

[data-tooltip] {
  position: relative;
  /* border-bottom: 1px dashed #000; */
  /* cursor: help */
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  /* pointer-events: none; */
  content: attr(data-tooltip);
  left: calc(100% + 1px);
  bottom: 10px;
  color: #2a292b;
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.6);
  background-color: #eaeaea;
  z-index: 9999;
  padding: 8px;
  width: 150px;
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}

.qrcode:hover,
.qrcode:active {
  position: absolute;
  bottom: 100%;
  left: 30%;
  transform: scale(3.5);
}

.qrcode-mobile:hover,
.qrcode-mobile:active {
  position: absolute;
  bottom: 10%;
  left: 40%;
  transform: scale(3.5);
}

table {
  width: 100%;
  border-spacing: 0px;
}

th {
  text-align: left;
  position: sticky;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  font-weight: 600;
  padding-bottom: 25px;
  position: sticky;
  border-bottom: 1px solid #cccccc;
  color: #e2101a;
}

td {
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0px;
  color: #414142;
}

table {
  position: relative;
}

thead {
  position: sticky;
  background: white;
  top: 0px;
}

tbody tr:last-child td {
  border: unset;
}

/* set a fixed height for the slider container */
#OffersSection .slider-container {
  /* height: 400px; */
  overflow: hidden;
}

/* hide the overflow from the slider */
#OffersSection .slick-list {
  overflow: hidden;
  margin-left: 20px;
  margin-top: 10px;
}

/* set the width of the individual slide elements */
#OffersSection .slick-slide {
  padding: 0px 30px 0px 46px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

#MobileOfferSection .slick-slide {
  padding: 0px 30px 0px 85px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
