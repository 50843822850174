.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    max-width: auto;
}


.tab {
    margin: 0px 10px;
    height: 27.84px;
    padding: 5px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    text-align: center;

    cursor: pointer;

    color: #2a292b;

    transition: 0.2s;
}

.tabsInnerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
    margin: auto;

}

.tabsOutterContainer {
    display: flex;
    justify-content: center;
    max-height: 220px;
    width: 90%;
    margin-left: 2%;
}

.scrollButtonLeft {
    font-size: 37px;
    margin-bottom: 5px;
    cursor: pointer;
    color: rgba(128, 127, 127, 0.7);
}

.scrollButtonRight {
    margin-left: 136%;
    margin-bottom: 5px;
    font-size: 37px;
    cursor: pointer;
    color: rgba(128, 127, 127, 0.7);
}

.scrollButton:hover {
    color: #807F7F;
}