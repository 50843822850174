.whatsApp {
  position: fixed;
  z-index: 1040;
  right: 30px;
  bottom: 20vh;
  height: 54px;
  background-color: #f4f4f4;
  border-radius: 16px;
  display: flex;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
}

.whatsApp .icon {
  background-image: linear-gradient(to top, #25cf43 100%, #61fd7d);
  height: 54px;
  width: 54px;
  min-width: 54px;
  border-radius: 16px;
}

.whatsApp .needSupport {
  padding: 8px 12px;
  font-family: "Vodafone";
  font-size: 16px;
  text-align: left;
  color: #333;
}

.container {
  margin: 0 150px 30px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.information {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 80px;
}

.infoSec {
  flex: 1;
}

.infoSec .title {
  font-family: "Manrope";
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #031f35;
  margin-bottom: 20px;
}

.infoSec .description {
  font-family: "Manrope";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #031f35;
}

.infoSec .description span {
  font-weight: bold;
}

.knowmore {
  font-family: "Manrope";
  font-size: 18px;
  text-align: left;
  color: #c80600;
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin-top: 8px;
}

.imageSec {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 32px;
  background-image: url(../../internalImages/background_img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 70%;
}

.imageSec>div {
  display: flex;
  gap: 32px;
}

.imageTwo {
  position: relative;
  top: -34px;
}

.imageSec img {
  width: 154px;
}

.mobileImageDiv {
  margin: 48px 0px 20px;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 0 50px 30px 50px;
  }

  .imageSec img {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 20px 30px 20px;
  }

  .information {
    flex-direction: column;
  }

  .information.columnReverse {
    flex-direction: column-reverse;
  }

  .imageSec img {
    width: 154px;
  }
}

@media screen and (max-width: 700px) {

  .information {
    padding-top: 0px;
  }

  .container {
    gap: 60px;
  }
}

@media screen and (max-width: 480px) {
  .imageSec img {
    width: 95px;
  }
}