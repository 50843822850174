.autocomplete {
    position: relative;
    display: inline-block;
    width:  206px;
}

.autocomplete-items {
    position: absolute;
    z-index: 999999;
    /*position the autocomplete items to be the same width as the container:*/
    top: 74%;
    left: 0;
    right: 0;
}

.autocomplete-items main:hover {
    background-color: #e9e9e9; 
}

.autocomplete-items main {
    /* padding: 10px; */
    color: black;
    /* padding-left: 12px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    cursor: pointer;
    width: 137px;
    box-shadow: 0px 1px 1px 0px #c80600;
    background-color: #fff; 
    /* border-bottom: 1px solid #d4d4d4;  */
}
.autocomplete-items main div {
    width: 70%;
    margin: auto;
    padding: 10px 10px 10px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items main:last-child{
    border-radius: 0px 0px 15px 15px;
}

.autocomplete-items main:last-child div{
    border-bottom:unset;
}

.autocomplete-active {
    background-color: DodgerBlue !important; 
    color: #ffffff; 
}

