.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;

    width: 478px;
    height: 175px;

    background: #FFFFFF;
    border: 1px solid rgba(128, 127, 127, 0.2);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    position: relative;

}


.image {
    width: 200px;
    height: 175px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* border: 1px solid black; */
}

.right {
    display: flex;
    flex-direction: column;
}

.headingContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 115px;
}

.heading {
    width: 238px;
    max-height: 84px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    /* or 150% */

    /* display: flex; */
    align-items: center;

    overflow: auto;

    color: #2a292b;
}

.subHeading {
    width: 100%;
    /* height: 11px; */
    /* margin-top: 15px; */

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    color: #2a292b;
}

.redirectButtonContainer {
    text-align: right;
    position: relative;
    right: 7px;
    bottom: 34px;
}

.redirectButton {
    height: 16px;
    padding: 10%;
    text-align: right;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    cursor: pointer;

    color: #C80600;
}

/* @media screen and (max-width: 1000px) {
    .container {
        margin-bottom: 24px;
        width: 317px;
        height: 127px;
    }

    .image {
        width: 144px;
        height: 127px;
    }

    .headingContainer {
        padding: 1rem 1rem 1rem 0rem;
    }

    .heading {
        width: 148px;
        height: 25px;
        font-size: 14px;
        line-height: 13px;
    }

    .subHeading {
        width: 46px;
        height: 13px;
        margin-top: 0px;
        font-size: 9px;
    }

    .redirectButton {
        height: 16px;
        padding: 0px;
        font-size: 8px;
        margin-right: 27px;
    }
} */