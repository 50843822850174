.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;

    width: 317px;
    height: 127px;

    background: #FFFFFF;
    border: 1px solid rgba(128, 127, 127, 0.2);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

}


.image {
    width: 144px;
    height: 127px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* border: 1px solid black; */
}

.right {
    display: flex;
    flex-direction: column;
}

.headingContainer {
    padding: 1rem 1rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 69px;
}

.heading {
    width: 150px;
    max-height: 52px;

    margin-top: 2px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    /* line-height: 16px; */

    /* display: flex; */
    align-items: center;
    overflow: auto;

    color: #2a292b;
}

.subHeading {
    /* width: 50px; */
    /* height: 13px; */
    margin-top: 0px;
    /* margin-left: 100px; */

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    /* line-height: 13px; */
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    color: #2a292b;
}

.redirectButtonContainer {
    text-align: right;
}

.redirectButton {
    /* height: 16px;
    padding: 0px; */

    margin-right: 27px;
    text-align: right;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    /* line-height: 16px; */
    /* identical to box height, or 100% */

    cursor: pointer;

    color: #C80600;
}

@media screen and (max-width: 1000px) {
    .redirectButton {
        height: 16px;
        padding: 0px;
        font-size: 8px;
        margin-right: 17px;
    }
}